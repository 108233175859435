<template>
    <div class="container my-5">
        <div class="d-flex flex-column align-items-center">
            <h4>Reset Password</h4>
            <form @submit="handleSubmit">
                <div class="form-group">
                    <div class="my-2 text-center">
                        Reset the password for your account.
                    </div>
                    <input
                        type="password"
                        v-model="password"
                        placeholder="new password"
                        class="form-control"
                    />
                </div>
                <div class="form-group">
                    <input
                        type="password"
                        v-model="passwordConfirm"
                        placeholder="confirm password"
                        class="form-control"
                    />
                </div>
                <button class="btn">
                    {{ processingForm ? "Please wait..." : "Reset Password" }}
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import Api from "../../apis/Api"
import request from "../../apis/request"

export default {
    data() {
        return {
            processingForm: false,
            submittedForm: false,
            password: "",
            passwordConfirm: "",
        }
    },
    mounted() {
        if (!this.$route.query.token) {
            this.$router.push({ name: "login" })
        }
    },
    methods: {
        async handleSubmit(e) {
            e.preventDefault()
            if (!this.verifyInputs()) return
            if (this.processingForm) return
            this.processingForm = true
            try {
                const res = await request.post("/reset-password", {
                    token: this.$route.query.token,
                    password: this.password,
                })
                this.$toasted.success(res.data.message || "Password reset success.")
                this.submittedForm = true
                window.location.href = "/login"
            } catch (err) {
                console.log(err.response.data)
                this.$toasted.error(
                    (err.response && err.response.data && err.response.data.message) ||
                        "Could not reset the password."
                )
            }
            this.processingForm = false
        },
        verifyInputs() {
            if (!this.password || !this.passwordConfirm) {
                this.$toasted.error("Please fill up the fields.")
                return false
            }
            if (this.password.length < 6) {
                this.$toasted.error("Password length must be at least 6 characters.")
                return false
            }
            if (this.password !== this.passwordConfirm) {
                this.$toasted.error("Password and confirm password doesn't match.")
                return false
            }
            return true
        },
    },
}
</script>

<style></style>
